import React, { useEffect, useState } from "react";
import "../assets/css/rdmportal.css";
import APIService from "../utils/APIService";
import LayoutWrapper from '../components/ui/LayoutWrapper';
import { urlEndpoints } from "../utils/APIHelper";
import { useTranslation } from 'react-i18next';
import { toast, ToastContainer } from "react-toastify";
import Input from "../components/common/Input";
import { getToken } from "../Helper";
import editicon from "../assets/images/edit-icon.svg";
import eyeIcons from "../assets/images/eye.svg";


const TenantInformation = () => {
    const access = getToken();
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [tenantName, setTenantName] = useState("");
    const [thingGroupData, setThingGroupData] = useState(null);
    const [loderText, setLoderText] = useState("Loading");
    const { t } = useTranslation();

    const softRefresh = () => {
        if (!tenantName) return;
        APIService(
            urlEndpoints.getThingGroupInformation,
            { Authorization: `Bearer ${access}` },
            { params: tenantName }
        )
            .then((response) => {
                setThingGroupData(response.data);
            })
            .catch((error) => {
                toast.error(t('message.SERVER_ERROR'), {
                    autoClose: 2000,
                    theme: "dark",
                });
            });
    };
    useEffect(() => {
        if (thingGroupData) {
            const interval = setInterval(() => {
                softRefresh();
            }, 10000);
            return () => clearInterval(interval);
        }
    }, [thingGroupData, tenantName]);

    const getThingGroupInformation = () => {

        if (!tenantName) {
            toast.error('TenantName is required', { autoClose: 2000, theme: 'dark' });
            return;
        }

        setLoaderVisibility(true);
        APIService(
            urlEndpoints.getThingGroupInformation,
            { Authorization: `Bearer ${access}` },
            { params: tenantName }
        )
            .then((response) => {
                console.log("Response : " + response.data);
                setThingGroupData(response.data);
                setLoaderVisibility(false);
            })
            .catch((error) => {
                setLoaderVisibility(false);
                if (error.response && error.response.status === 404) {
                    toast.error(t('message.TENANT_NOT_FOUND'), {
                        autoClose: 2000,
                        theme: "dark",
                    });
                } else {
                    toast.error(t('message.SERVER_ERROR'), {
                        autoClose: 2000,
                        theme: "dark",
                    });
                }
            });
    };

    const updateDeviceReportedShadow = (type, thingName, latestVersion, otherVer) => {
        console.log("type " + type);
        console.log("thigName " + thingName);
        console.log("latestVersion " + latestVersion);
        let dtoToSend;
        if (type === 'OS') {
            dtoToSend = {
                "thingName": thingName,
                "osVersion": latestVersion,
                "fpgaVersion": otherVer,
                "type": type
            };
        } else if (type === 'FPGA') {
            dtoToSend = {
                "thingName": thingName,
                "osVersion": otherVer,
                "fpgaVersion": latestVersion,
                "type": type
            };
        }

        console.log("dtoToSend:", JSON.stringify(dtoToSend));
        setLoaderVisibility(true);
        APIService(
            urlEndpoints.updateDeviceShadow,
            { Authorization: `Bearer ${access}` },
            JSON.stringify(dtoToSend)
        )
            .then((response) => {
                console.log("Response : " + response.data);
                setLoaderVisibility(false);
            })
            .catch((error) => {
                toast.error(t('message.SERVER_ERROR'), {
                    autoClose: 2000,
                    theme: "dark",
                });
            });
    };
    return (
        <LayoutWrapper loderText={loderText} title={t('labels.DEVICE_INFORMATION')} visibility={loaderVisibility}>
            <section class="inner-wrapper table-top">
                <div class="w-100">
                    <div class="cus-row">
                        <label class="col-form-label">{t('labels.TENANT_NAME')}</label>
                        <Input
                            parentDivClassName="etherner-input  dpw-50"
                            type="text"
                            id="tenantName"
                            name="tenantName"
                            autoComplete="off"
                            required={true}
                            className="sa-input"
                            maxLength={30}
                            onChange={(e) => setTenantName(e.target.value)}
                        />
                    </div>
                    <div class="d-flex justify-content-end mt-4">
                        <button class="btn btn-secondary" type="submit" onClick={getThingGroupInformation}>{t('labels.SEARCH')}</button>
                    </div>
                    {thingGroupData && (
                        <div className="thin-border table-scroll">
                            <h3>{thingGroupData.thingGroupName}</h3>
                            <table className="table table-dark table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>Thing Name</th>
                                        <th colSpan={2}>OS Version</th>
                                        <th colSpan={2}>FPGA Version</th>
                                        {/* <th className="text-center" width="20%">Actions</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {thingGroupData.things.map((board, idx) => (
                                        <tr key={idx}>
                                            <td>{board.name}</td>
                                            <td>{board.osVersion}</td>
                                            <td>
                                                {board.eligibleforOsUpdate ? (
                                                    <button
                                                        className="btn btn-secondary"
                                                        type="button"
                                                        onClick={() =>
                                                            !board.osNotifyToDevice &&
                                                            board.eligibleforOsUpdate &&
                                                            updateDeviceReportedShadow('OS', board.name, board.osLatestVersion, board.fpgaVersion)
                                                        }
                                                        disabled={board.osNotifyToDevice}
                                                    >
                                                        {board.osNotifyToDevice ? board.osStatus : t('labels.UPDATE')}
                                                    </button>
                                                ) : (
                                                    "-"
                                                )}
                                            </td>
                                            <td>{board.fpgaVersion}</td>
                                            <td>
                                                {board.eligibleforFPGAUpdate ? (
                                                    <button
                                                        className="btn btn-secondary"
                                                        type="button"
                                                        onClick={() =>
                                                            !board.fpgaNotifyToDevice &&
                                                            board.eligibleforFPGAUpdate &&
                                                            updateDeviceReportedShadow('FPGA', board.name, board.fpgaLatestVersion, board.osVersion)
                                                        }
                                                        disabled={board.fpgaNotifyToDevice}
                                                    >
                                                        {board.fpgaNotifyToDevice ? board.fpgaStatus : t('labels.UPDATE')}
                                                    </button>
                                                ) : (
                                                    "-"
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </section>
        </LayoutWrapper>
    );
};
export default TenantInformation;
import axios from 'axios';
import {Config} from '../Config';
import { defaultHeaders } from './APIHelper';

const AxiosInstance = (headers = defaultHeaders) =>
  axios.create({
    baseURL: Config.BACKEND_URL,
    timeout: 20000,
    headers,
  });

export default AxiosInstance;

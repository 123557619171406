import React, { useState } from 'react';
import { useSearchParams, useNavigate } from "react-router-dom";
import APIService from "../utils/APIService";
import { urlEndpoints } from "../utils/APIHelper";
import { useTranslation } from 'react-i18next';
import "../assets/css/login.css";
import Input from "../components/common/Input"
import Loader from "../components/common/Loader"
import profilepicedit from '../assets/images/profile-pic-edit.png';
import { toast, ToastContainer } from "react-toastify";
import softAcuityLogoWhite from "../assets/images/softAcuityLogoWhite.svg";
import exclamatorysign from "../assets/images/exclamatory-sign.svg";
import PasswordInput from "../components/common/PasswordInput";

const Login = ({ setAuthenticated }) => {
  const [loaderVisibility, setLoaderVisibility] = useState(false);
  const [loderText, setLoderText] = useState("Loading");
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [passwordType, setPasswordType] = useState('password');

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    return re.test(String(email).toLowerCase());
  };
  const onShow = (value) => {
    var currentElement = document.getElementById("password");
    if (currentElement.type === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password")
    }
  }

  const handleLogin = async (event) => {
    event.preventDefault();

    console.log("userName >> " + userName);
    /*if (!validateEmail(userName)) {
      toast.error('Incorrect email format', { autoClose: 2000, theme: 'dark' });
      return;
    }*/

    if (!userName) {
      toast.error('UserName is required', { autoClose: 2000, theme: 'dark' });
      return;
    }
    if (!password) {
      toast.error('Password is required', { autoClose: 2000, theme: 'dark' });
      return;
    }
    if (password.length < 8) {
      toast.error('Password must be at least 8 characters', { autoClose: 2000, theme: 'dark' });
      return;
    }
    if (!/[A-Z]/.test(password)) {
      toast.error('Password must contain at least one uppercase letter', { autoClose: 2000, theme: 'dark' });
      return;
    }
    if (!/[a-z]/.test(password)) {
      toast.error('Password must contain at least one lowercase letter', { autoClose: 2000, theme: 'dark' });
      return;
    }
    if (!/[^\w]/.test(password)) {
      toast.error('Password must contain at least one special character', { autoClose: 2000, theme: 'dark' });
      return;
    }
    if (!/[0-9]/.test(password)) {
      toast.error('Password must contain at least one number', { autoClose: 2000, theme: 'dark' });
      return;
    }
    setLoaderVisibility(true);
    const params = { userName, password };
    try {
      console.log("Hit Login");
      setLoaderVisibility(true);
      APIService(urlEndpoints.login, {}, params)
        .then((response) => {
          console.log("Then ....");
          sessionStorage.clear();
          localStorage.clear();
          sessionStorage.setItem("token", JSON.stringify(response.data));
          setLoaderVisibility(false);
          navigate("/generateOTP");
        })
        .catch((error) => {
          console.log("catch error ...." + error.response.data);
          setLoaderVisibility(false);
          if (error.response.data) {
            toast.error(error.response.data, {
              autoClose: 2000,
              theme: "dark",
            });
          } else {
            toast.error(t('message.SERVER_ERROR'), {
              autoClose: 2000,
              theme: "dark",
            });
          }
        });
    } catch (error) {
      setLoaderVisibility(false);
      const errorMessage = error.response?.data?.message || 'Server error';
      toast.error(errorMessage, { autoClose: 2000, theme: 'dark' });
    }
  };

  return (
    <>
      <div className="login-bg">
        <div className="login-wrap">
          <div className="content">
            <div className="logo-wrap">
              <img className="img-fluid" id="softAcuityLogo" src={softAcuityLogoWhite} alt="" />
            </div>
            <h1 className="text-center text-white my-5">Login</h1>
            <h4 className="text-center text-white mb-5">Login to your account</h4>
            <div className="m-4">
              <form onSubmit={handleLogin} className="w-75">
                <div className="mb-3">
                  <Input parentDivClassName="field-holder"
                    labelClassName="floating-label"
                    label="UserName"
                    type="text"
                    value={userName}
                    required={true}
                    onChange={(e) => setUserName(e.target.value)}
                    className="inputText"
                  />
                </div>
                <div className="mb-3">
                  <PasswordInput parentDivClassName="field-holder"
                    labelClassName="floating-label"
                    id="password"
                    label="Password"
                    value={password}
                    type={passwordType}
                    required={true}
                    onChange={(e) => setPassword(e.target.value)}
                    className="inputText"
                    onShow={onShow}
                    fieldType={passwordType == 'password' ? true : false}

                  />
                </div>
                <div className="text-right mb-5">
                  <button type="submit" className="btn btn-signin">LogIn</button>
                </div>
              </form>
              {error && <p style={{ color: 'red' }}>{error}</p>}
            </div>
          </div>
        </div>
      </div>
      <Loader loderText={loderText} visibility={loaderVisibility} leftAlign={'14%'} />
      <ToastContainer limit={1} />
    </>
  );
};

export default Login;

import React from "react";
const Loader = ({loderText , visibility, leftAlign }) => {
  return (
    <>
      {visibility && (
        <div className="spinner-box" style={leftAlign ? { left: leftAlign } : null}>
          {leftAlign && <style>{`.spinner-box { left: ${leftAlign}; }`}</style>}
          <div className="circle-border">
            <div className="circle-core"></div>
          </div>
          <div className="ml-2 ml-loader">{loderText}...</div>
        </div>
      )}
    </>
  );
};
export default Loader;

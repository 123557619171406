import React from "react";
import { useSearchParams } from 'react-router-dom';
import Header from "./Header";
import Sidebar from "./Sidebar";
import '../../assets/css/layoutwrapper.css'
import Loader from '../common/Loader';
import { tokenParse } from "../../Helper";
import { ToastContainer, toast } from "react-toastify";
import { Config } from "../../Config";
import SockJsClient from "react-stomp";
const LayoutWrapper = ({ ...props }) => {
  const tokenparse = tokenParse();
  const [searchParams, setSearchParams] = useSearchParams();
  let onlyProfile = false;
  if (sessionStorage.getItem("onlyProfile")) {
    onlyProfile = true;
  }
  console.log("onlyProfile :: " + onlyProfile);
  return (
    <div class="container-fluid h-100">
      <Header title={props.title} backUrl={props.backUrl} />
      <div class="col-md-2 col-sidebar">
        <Sidebar />
      </div>
      <div class="col-md-7 main-content">
        {props.children}
      </div>
      <ToastContainer limit={1} />
      <Loader loderText={props.loderText} visibility={props.visibility}/>
      {props.topics && props.onMessage && (<SockJsClient
        url={Config.SOCKET_URL_VOICE_AUTHENTICATION}
        topics={props.topics}
        onMessage={props.onMessage}
      />)}
    </div>
  );
};

export default LayoutWrapper;

import React, { useEffect, useState } from "react";
import LayoutWrapper from '../components/ui/LayoutWrapper';
import { useNavigate } from "react-router-dom";
import "../assets/css/rdmportal.css";
import { useTranslation } from 'react-i18next';
import fetchLanguage from "../utils/LanguageService";
import { getToken } from "../Helper";
const CommandControl = () => {
  const navigate = useNavigate();
  const [loaderVisibility, setLoaderVisibility] = useState(false);
  const [loderText, setLoderText] = useState("Loading");
  const [title, setTitle] = useState("Third Party Setup");
  const { t, i18n } = useTranslation();
  const access = getToken();
  const [language, setLanguage] = useState('en');
  return (<LayoutWrapper loderText={loderText} title={t('labels.DEVICE_MANAGEMENT')} visibility={loaderVisibility}>
    <section class="inner-wrapper table-top">
            <div class="w-100">
                <form>
                    <div class="cus-row">
                        <label class="col-form-label">{t('labels.DEVICE_MANAGEMENT')}</label>
                    </div>
                    <div class="d-flex justify-content-end mt-4">
                        <button class="btn btn-secondary" type="submit">{t('labels.SAVE')}</button>
                    </div>
                </form>
            </div>
        </section>
  </LayoutWrapper>);
}
export default CommandControl;   
import React, { useEffect, useState } from "react";
import LayoutWrapper from '../components/ui/LayoutWrapper';
import "../assets/css/rdmportal.css";
import Input from "../components/common/Input";
import { useTranslation } from 'react-i18next';
const DeviceMovement = () => {
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [loderText, setLoderText] = useState("Loading");
    const { t, i18n } = useTranslation();
    return (<LayoutWrapper loderText={loderText} title={t('labels.DEVICE_MOVEMENT')} visibility={loaderVisibility}>
        <section class="inner-wrapper table-top">
            <div class="w-100">
                <form>
                    <div class="cus-row">
                        <label class="col-form-label">{t('labels.THING_NAME')}</label>
                        <Input
                            parentDivClassName="etherner-input  dpw-50 "
                            type="text"
                            id="thingName"
                            name="thingName"
                            autoComplete="off"
                            required={true}
                            className="sa-input"
                            maxLength={30}
                        />
                    </div>
                    <div class="cus-row">
                        <label class="col-form-label">{t('labels.SOURCE_THING_GROUP')}</label>
                        <Input
                            parentDivClassName="etherner-input  dpw-50 "
                            type="text"
                            id="srcThingGroup"
                            name="srcThingGroup"
                            autoComplete="off"
                            required={true}
                            className="sa-input"
                            maxLength={30}
                        />
                    </div>
                    <div class="cus-row">
                        <label class="col-form-label">{t('labels.TARGET_THING_GROUP')}</label>
                        <Input
                            parentDivClassName="etherner-input  dpw-50 "
                            type="text"
                            id="trgThingGroup"
                            name="trgThingGroup"
                            autoComplete="off"
                            required={true}
                            className="sa-input"
                            maxLength={30}
                        />
                    </div>
                    <div class="d-flex justify-content-end mt-4">
                        <button class="btn btn-secondary" type="submit">{t('labels.SAVE')}</button>
                    </div>
                </form>
            </div>
        </section>
    </LayoutWrapper>);
}
export default DeviceMovement;   
import React, { useEffect, useState } from "react";
import "../assets/css/rdmportal.css";
import LayoutWrapper from '../components/ui/LayoutWrapper';
import { useTranslation } from 'react-i18next';
import APIService from "../utils/APIService";
import { urlEndpoints } from "../utils/APIHelper";
import { getToken } from "../Helper";
import { toast, ToastContainer } from "react-toastify";

const OnBoardingOTP = () => {
    const access = getToken();
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [loderText, setLoderText] = useState("Loading");
    const [otp, setOTP] = useState('');
    const { t, i18n } = useTranslation();

    const generateOTP = () => {
        setLoaderVisibility(true);
        APIService(
            urlEndpoints.generateOTP,
            {Authorization: `Bearer ${access}`},
            {}
        )
            .then((response) => {
                console.log("Response : " + response.data);
                setOTP(response.data);
                setLoaderVisibility(false);
            })
            .catch((error) => {
                toast.error(t('message.SERVER_ERROR'), {
                    autoClose: 2000,
                    theme: "dark",
                });
            });
    };
    return (<LayoutWrapper loderText={loderText} title={t('labels.DEVICE_ONBOARDING')} visibility={loaderVisibility}>
        <section class="inner-wrapper table-top">
            <div class="w-75">
                <div class="support-wrap">
                    <div class="support-content">
                        <p class="text-white system-text-alignment onboarding-align" dangerouslySetInnerHTML={{ __html: t('labels.DEVICE_ONBOARDING_MSG') }}>
                        </p>
                        <button class="btn btn-secondary pull-right btn-reset" onClick={generateOTP}>{t('labels.GENERATE_OTP')}</button>
                    </div>
                </div>
                <div class="text-center optMargin">
                    <div classs="div-margin">
                        <span class="font-24 mb-4 text-center">
                            <span class="counter">{otp}</span>
                        </span>
                    </div>
                </div>
            </div>
        </section>
    </LayoutWrapper>);
}
export default OnBoardingOTP;   
import React, { useRef, useEffect } from "react";
import eyeIcons from "../../assets/images/eye.svg";
import eyeCrossedIcons from "../../assets/images/eye-crossed.svg";
import errorIcons from "../../assets/images/error-icon.svg";
const PasswordInput = ({
    id,
    autoComplete,
    refFocus,
    onBlur,
    maxLength,
    required,
    value,
    label,
    labelClassName,
    name,
    placeholder,
    type,
    onChange,
    touched,
    error,
    className,
    readOnly,
    parentDivClassName,
    onClear,
    onkeypress,
    style,
    onShow,
    fieldType
}) => {
    const inputReference = useRef(null);
    useEffect(() => {
        if (refFocus) {
            inputReference.current.focus();
        }
    }, []);
    return (
        <div className={parentDivClassName + (error && touched ? ' error-input' : ' no-error-input')}>
            <input
                id={id}
                type={type}
                value={value}
                name={name}
                maxLength={maxLength}
                ref={inputReference}
                autoComplete={autoComplete}
                className={className}
                placeholder={placeholder}
                onChange={onChange}
                onBlur={onBlur}
                readOnly={readOnly}
                onKeyDown={onkeypress}
                style={style}
            />
            {label && (
                <span className={labelClassName + (value?.length > 0 ? ' inputTextNoFocusWithValue' : '')}>
                    {label} {required && <sup>*</sup>}
                </span>)}
            {label && error && touched && (
                <span className="input-group-text"><img className="img-fluid" src={errorIcons} alt="" /></span>
            )}

            {!label && error && touched && (
                <img className="img-fluid" src={errorIcons} alt="" />
            )}
            {label && value?.length > 0 && !readOnly && fieldType && (
                <span className="input-group-text" onClick={() => onShow(id)}><img className="img-fluid image-icon" src={eyeIcons} alt="" /></span>
            )}

            {label && !error && value?.length > 0 && !readOnly && !fieldType && (
                <span className="input-group-text" onClick={() => onShow(id)}><img className="img-fluid image-icon" src={eyeCrossedIcons} alt="" /></span>
            )}
            {!label && !error && value?.length > 0 && !readOnly && (
                <img onClick={() => onShow(name)} className="img-fluid image-icon" src={fieldType ? eyeIcons : eyeCrossedIcons} alt="" />
            )}
            {error && touched && (
                <span className={(label ? 'error-msg' : 'error')}>{error}</span>
            )}
        </div>
    );
};
export default PasswordInput;
import {
  DELETE,
  GET,
  GET_PARAMS,
  PATCH,
  POST,
  POST_PARAMS,
  PUT,
  DELETE_PARAMS,
  requestConfig,
} from "./APIHelper";
import AxiosInstance from "./AxiosInstance";

const getResultData = async (
  apiURLEndpoint,
  requestType,
  requestParams,
  configuration
) => {
  let response = {};
  try {
    switch (requestType) {
      case GET_PARAMS:
        response = await AxiosInstance().get(
          apiURLEndpoint + "/" + requestParams.params,
          configuration
        );
        break;
      case POST_PARAMS:
        response = await AxiosInstance().post(
          `${apiURLEndpoint}/${requestParams.params}`,
          requestParams.data,
          configuration
        );
        break;
      case GET:
        response = await AxiosInstance().get(apiURLEndpoint, configuration);
        break;
      case POST:
        response = await AxiosInstance().post(apiURLEndpoint, requestParams, configuration);
        break;
      case PUT:
        response = await AxiosInstance().put(
          apiURLEndpoint,
          requestParams,
          configuration
        );
        break;
      case DELETE:
        response = await AxiosInstance().delete(apiURLEndpoint);
        break;
      case PATCH:
        response = await AxiosInstance().patch(apiURLEndpoint, requestParams);
        break;
      case DELETE_PARAMS:
        response = await AxiosInstance().delete(
          apiURLEndpoint + "/" + requestParams.params,
          configuration
        );
        break;
      default:
        break;
    }
  } catch (error) {
    throw error;
  }
  return response;
};

const APIService = (
  urlEndpoints = {},
  requestHeader = {},
  requestParams = {}
) => {
  const apiURLEndpoint = urlEndpoints.endpoint;
  const apiMethodType = urlEndpoints.methodType;
  const configuration = requestConfig(urlEndpoints.methodType, requestHeader);
  console.log("requestParams >> " + requestParams);
  return getResultData(
    apiURLEndpoint,
    apiMethodType,
    requestParams,
    configuration
  );
};

export default APIService;

export const tokenParse = () => {
  if (JSON.parse(sessionStorage.getItem("token")) != null) {
    const base64Url = JSON.parse(sessionStorage.getItem("token")).split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse((atob(base64)))
  } else {
    return null;
  }
};

export const getToken = () => {
  const tokenString = sessionStorage.getItem("token");
  const userToken = JSON.parse(tokenString);
  return userToken;
};

export const onlyOtherRoleAcess = ["/generateOTP", "/tenantManagement", "/tenatInformation", "/commandControl", "/deviceMovement"];


import React, { useRef, useEffect } from "react";
import clearIcons from "../../assets/images/clear-Icons.svg";
import errorIcons from "../../assets/images/error-icon.svg";
const Input = ({
  autoComplete,
  refFocus,
  onBlur,
  maxLength,
  required,
  value,
  label,
  labelClassName,
  name,
  placeholder,
  type,
  onChange,
  touched,
  error,
  className,
  readOnly,
  parentDivClassName,
  onClear,
  onkeypress,
  style,
  onClick
}) => {
  const inputReference = useRef(null);
  useEffect(() => {
    if (refFocus) {
      inputReference.current.focus();
    }
  }, []);
  return (
    <div className={parentDivClassName + ( error && touched ? ' error-input' : ' no-error-input')}>
      <input
        type={type}
        value={value}
        name={name}
        maxLength={maxLength}
        ref={inputReference}
        autoComplete={autoComplete}
        className={className}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        readOnly={readOnly}
        onKeyDown={onkeypress}
        style={style}
        onClick={onClick}
      />
       {label && (
        <span className= {labelClassName + (value?.length>0 ? ' inputTextNoFocusWithValue' : '')}>
          {label} {required && <sup>*</sup>}
        </span>)}
      {label && error && touched &&  (
        <span className="input-group-text"><img className="img-fluid" src={errorIcons} alt=""/></span>
       )}

       {!label && error && touched  &&  (
        <img  className="img-fluid" src={errorIcons} alt=""/>
      )}
      
       {label && !error && touched && value?.length>0 && !readOnly   &&  (
        <span className="input-group-text" onClick={() => onClear(name)}><img className="img-fluid" src={clearIcons} alt=""/></span>
       )}
      
      {!label && !error && touched && value?.length>0 && !readOnly  &&  (
        <img onClick={() => onClear(name)} className="img-fluid" src={clearIcons} alt=""/>
      )}
       {error && touched && (
          <span className={(label  ? 'error-msg' : 'error')}>{error}</span>
      )} 
    </div>
  );
};
export default Input;
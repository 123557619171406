import React, { useEffect } from "react";
import "./assets/css/bootstrap.min.css";
import "./assets/css/app.css";
import "react-toastify/dist/ReactToastify.css";
import Routes from "./Routes";
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom'
function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.has("onlyProfile")) {
      if (searchParams.get("onlyProfile").length != 0) {
        sessionStorage.setItem("onlyProfile", searchParams.get("onlyProfile"));
      }
    } else {
      sessionStorage.removeItem("onlyProfile");
    }
    if (searchParams.has("token")) {
      if (searchParams.get("token").length != 0) {
        sessionStorage.setItem("token", JSON.stringify(searchParams.get("token")));
        navigate(location.pathname);
      }
    }
  }, []);
  return <Routes />;
}
export default App;

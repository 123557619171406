// Request method types for handling the cases based on endpoint method type
export const GET = "GET";
export const GET_PARAMS = "GET_PARAMS";
export const POST = "POST";
export const PUT = "PUT";
export const DELETE = "DELETE";
export const PATCH = "PATCH";
export const MULTIPART = "MULTIPART";
export const CONCURRENT = "CONCURRENT";
export const DELETE_PARAMS = "DELETE_PARAMS"
export const POST_PARAMS = "POST_PARAMS";
// Default Header while setting up the network call
export const defaultHeaders = {
  "Content-Type": "application/json",
  //'Content-Type': 'application/x-www-form-urlencoded',
  //'Accept': 'application/json',
};
// Request configuration for passing into API's request
export const requestConfig = (
  methodType = GET,
  extraHeader = defaultHeaders
) => ({
  method: methodType,
  headers: extraHeader,
});
// Request parameters for passing into API's request body
export const requestParam = (
  methodType = GET,
  extraHeader = defaultHeaders,
  body = {},
  url = ""
) => ({
  method: methodType,
  url: url,
  headers: extraHeader,
});

/**
 * Endpoint with method type while initiating the network call(Please refer below statement/s for further clarification)
 * endpoint: the END that is pointing to the API to be called
 * methodType: the TYPE of the method to be called (GET, POST, PUT, DELETE, MULTIPART, .        ..)
 * isPrivate: the status of the API either PRIVATE or PUBLIC,
 * if it's PRIVATE then need to add this key:pair value ie. isPrivate: true
 * and don't add key:pair in case of PUBLIC, otherwise.
 */
export const urlEndpoints = {
  login: { endpoint: "/login", methodType: POST },
  logout: { endpoint: "/logout", methodType: GET },
  createTenant: { endpoint: "/tenant/create", methodType: POST },
  allThingGroups: { endpoint: "/deviceManagement/getAllthingGroups", methodType: GET },
  generateOTP: { endpoint: "/deviceManagement/generateOTP", methodType: GET },
  getThingGroupInformation: { endpoint: "/deviceManagement/thingGroup", methodType: GET_PARAMS},
  updateDeviceShadow: { endpoint: "/devices/updateShadow", methodType: POST}
};

